import React from "react"
import { graphql, Link, navigate } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import SearchInput from "../components/SearchInput"
import withLocation from "../../lib/HOC/withLocation"

const slugify = require("../../lib/slugify");

// import BrowseList from "../components/BrowseList"

import css from "./index.module.scss"
// import card from "../templates/card"

const Index = ({ data, search }) => {
  const letterLinks = [...Array(26).keys()]
    .map(index => index + 97)
    .map(charCode => (
      <li key={charCode}>
        <Link to={`/${String.fromCharCode(charCode).toLowerCase()}/`}>
          {String.fromCharCode(charCode).toUpperCase()}
        </Link>
      </li>
    ))

  const onSubmitSearch = (query, lang) => {
    navigate(`/search?search=${query}&lang=${lang}`, {})
  }

  const imageid = search.imageid && search.imageid.length
    ? parseInt(search.imageid, 10)
    : 0;
  const searchStr = search.str && search.str.length
    ? search.str
    : '';

  React.useEffect(() => {
    if (imageid > 0) {
      navigate(`/card/???/?startCard=${imageid}`);
    }
    if (searchStr.length) {
      navigate(`/card/${slugify(searchStr)}/`);
    }
  }, [imageid, searchStr]);

  return (
    <Layout>
      <HeadMeta />
      <PageHeader>Scanned card catalogue</PageHeader>
      <Breadcrumb route={[{ name: "Scanned card catalogue", href: "/" }]} />
      <h2>Search the Scanned Card Catalogue</h2>
      <SearchInput onSubmit={onSubmitSearch} />
      <br />
      <h3>
        <Link to="/tips/">Search Tips</Link>
      </h3>
      <p>Type in an author or subject. Some titles are also listed.</p>
      <h2>Browse by first letter</h2>
      <ul className={css.browseByFirst}>{letterLinks}</ul>
      <h2>What is the Scanned Card Catalogue?</h2>
      <p>
        The Scanned Card Catalogue contains photographic images of the cards in
        the State Reference Library's Card Catalogue. It contains published
        material received by the Library before 1980.
      </p>
      <h2>When to search the Scanned Card Catalogue</h2>
      <p>
        Always start your search in the {" "}
        <a href="https://search.sl.nsw.gov.au">online Catalogue</a>.
      </p>
      <p>
        Check the Scanned Card Catalogue if the item you are looking for is not
        in the online catalogue and it was published before 1980.
      </p>
      <h2>Using the Scanned Card Catalogue</h2>
      <p>
        The cards are arranged in one alphabetical sequence. Some of the
        photographic images of cards may not allow you to read them easily. If
        you have difficulty reading the cards and need further assistance,{" "}
        <a href="https://www.sl.nsw.gov.au/ask">ask a librarian</a> or go to the
        Information Desk if you are in the Library.
      </p>
      <h2>How to search the Scanned Card Catalogue</h2>
      <p>
        Enter your search term. Entry points into the catalogue have been placed
        approximately every 50th card. If what you are looking for is not an
        entry point, your search will take you to the closest alphabetical
        entry. Select this and browse through the cards.
      </p>
      {/* <BrowseList
        items={data.allCardsCsv.edges.map(cardEdge => {
          return {
            fields: {
              name: cardEdge.node.fields.CT_Term,
              slug: cardEdge.node.fields.slug,
            },
          }
        })}
        sortAlpha={true}
      /> */}
    </Layout>
  )
}

export default withLocation(Index);

export const query = graphql`
  query IndexQuery {
    allCardsCsv(
      filter: { fields: { CT_ID: { nin: [null, "", "CT_ID"] } } }
      sort: { fields: firstImageId }
    ) {
      edges {
        node {
          fields {
            CT_Term
            slug
          }
        }
      }
    }
  }
`
